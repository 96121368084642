'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

import Image from 'next/image';

import { cn } from '@/lib/utils';
import SliceCta from '@/slices/_shared/cta';
import DeviceRichText from '@/slices/_shared/device-rich-text';
import heading from '@/slices/_shared/heading';
import SliceContainer from '@/slices/_shared/slice-container';

/**
 * Props for `Hero`.
 */
export type HeroProps = SliceComponentProps<Content.HeroSlice>;

/**
 * Component for "Hero" Slices.
 */
const Hero = ({ slice }: HeroProps) => {
  const components = heading({
    theme: slice.variation === 'default' ? 'primary' : 'white',
  });

  return (
    <div
      className={cn(
        'relative',
        slice.variation === 'withBackground'
          ? 'bg-gradient-to-b from-foreground to-foreground-light'
          : '',
      )}
    >
      {slice.variation === 'withBackground' && slice.primary.background.url ? (
        <Image
          src={slice.primary.background.url}
          alt=""
          className="absolute left-0 top-0 z-0 size-full object-cover"
          role="presentation"
          fill
          // If the image from "images.prismic" domain, it went through imgix and can be optimized.
          unoptimized={
            !slice.primary.background.url?.includes('images.prismic')
          }
        />
      ) : null}

      <SliceContainer
        slice={slice}
        className={cn(
          'relative z-10 sm:gap-6',
          slice.primary.remove_top_spacing ? 'pt-0' : 'pt-16',
          slice.primary.remove_bottom_spacing ? 'pb-0' : 'pb-16',
        )}
      >
        <DeviceRichText
          components={components}
          richText={slice.primary.heading}
          mobileRichText={slice.primary.mobile_heading}
        />

        <DeviceRichText
          components={components}
          richText={slice.primary.text}
          mobileRichText={slice.primary.mobile_text}
          className="flex flex-col items-center"
        />

        <div className="flex flex-row gap-8">
          {slice.items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SliceCta key={index} field={item.button} />
          ))}
        </div>
      </SliceContainer>
    </div>
  );
};

export default Hero;
