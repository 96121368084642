'use client';

import { NumberField } from '@prismicio/client';
import { EmblaCarouselType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Button } from '@/app/_components/ui/button';
import { Icons } from '@/app/_components/ui/icons';
import { useBreakpoint } from '@/lib/style/hooks';
import { cn } from '@/lib/utils';

interface CarouselProps extends PropsWithChildren {
  speed: NumberField;
  itemsCount: number;
  Fallback?: ReactNode;
}

const Carousel = ({ children, speed, itemsCount }: CarouselProps) => {
  const [shouldDisplayArrow, setShouldDisplayArrow] = useState(false);

  // make sure carousel does not glich if minimum slides item are not met to loop
  const isDesktop = useBreakpoint('md');
  const isLargeDesktop = useBreakpoint('xl');

  const isContainerFullScreenWithoutLoop =
    (isDesktop && itemsCount < 3) || (isLargeDesktop && itemsCount < 4);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      axis: 'x',
      align: isContainerFullScreenWithoutLoop ? 'center' : 'start',
      loop: true,
      watchResize: true,
    },
    [
      Autoplay({
        // The amount sent by prismic is in seconds, we need milliseconds
        // By default we set it to 5 seconds
        delay: speed ? speed * 1000 : 5000,
        stopOnInteraction: false,
        stopOnMouseEnter: true,
      }),
    ],
  );

  const canScroll = useCallback(
    (innerEmblaApi: EmblaCarouselType) => {
      const tmpShouldDisplayArrow =
        innerEmblaApi.canScrollNext() ?? innerEmblaApi.canScrollPrev();
      if (
        tmpShouldDisplayArrow !== undefined &&
        shouldDisplayArrow !== tmpShouldDisplayArrow
      ) {
        setShouldDisplayArrow(tmpShouldDisplayArrow);
      }
    },
    [shouldDisplayArrow],
  );

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('init', canScroll);
      emblaApi.on('reInit', canScroll);
      emblaApi.reInit(); // weird fix to display items correctly (not the case on init)
    }
    return () => {
      if (emblaApi) {
        emblaApi.off('init', canScroll);
        emblaApi.off('reInit', canScroll);
      }
    };
  }, [emblaApi, canScroll]);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <section className="relative flex w-112 max-w-full justify-center md:w-full">
      <div
        className={cn(
          'flex max-w-full',
          !isContainerFullScreenWithoutLoop
            ? 'w-[var(--carouselWidth)]'
            : 'w-80 justify-center md:w-full',
        )}
      >
        <div className="w-min overflow-hidden py-6" ref={emblaRef}>
          <div
            className={cn('flex *:mr-10', 'mx-6 touch-pan-y backface-hidden')}
          >
            {children}
          </div>
        </div>
      </div>

      {shouldDisplayArrow ? (
        <div
          className={cn(
            'absolute inset-y-0 left-0 z-10 flex items-center bg-gradient-to-r from-white',
            'w-16 sm:w-32 md:w-48',
          )}
        >
          <Button
            onClick={onPrevButtonClick}
            className="ml-2 size-8 rounded-full px-1 sm:ml-4 sm:size-12 sm:px-2 md:ml-8"
          >
            <Icons.StraightLeftArrow className="size-8 sm:size-12" />
          </Button>
        </div>
      ) : null}

      {shouldDisplayArrow ? (
        <div
          className={cn(
            'absolute inset-y-0 right-0 z-10 flex items-center justify-end bg-gradient-to-l from-white',
            'w-16 sm:w-32 md:w-48',
          )}
        >
          <Button
            onClick={onNextButtonClick}
            cornerStyle="cornerRight"
            className="mr-2 size-8 rounded-full px-1 sm:mr-4 sm:size-12 sm:px-2 md:mr-8"
          >
            <Icons.RightArrow className="size-8 sm:size-12" />
          </Button>
        </div>
      ) : null}
    </section>
  );
};

export default Carousel;
