import { ContentRelationshipField, isFilled } from '@prismicio/client';
import { PrismicNextLink } from '@prismicio/next';

import { ArrowRightIcon } from '@radix-ui/react-icons';

import { Button } from '@/app/_components/ui/button';
import Link from '@/lib/translation/components/link';

import { ButtonDocumentData } from '../../../prismicio-types';

import mapInternalLinkToRoutes from './internal-link';

const mapButtonThemeToButtonVariant = {
  primary: 'default' as const,
  outline: 'outline' as const,
};

interface SliceCtaProps {
  field: ContentRelationshipField<'button'>;
  className?: string;
  size?: 'default' | 'xs' | 'sm' | 'lg' | 'xl' | 'icon';
}

const SliceCta = ({ field, className, size }: SliceCtaProps) => {
  const casted = field as ContentRelationshipField<
    'button',
    string,
    ButtonDocumentData
  >;
  if (!isFilled.contentRelationship(casted)) {
    return null;
  }

  if (!casted.data) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { internal_link, label, with_arrow, url, theme } = casted.data;

  return internal_link !== 'none' ? (
    <Link
      className={className}
      key={label}
      href={mapInternalLinkToRoutes[internal_link]}
    >
      <Button size={size} variant={mapButtonThemeToButtonVariant[theme]}>
        {label}
        {with_arrow ? <ArrowRightIcon className="ml-2 size-4" /> : null}
      </Button>
    </Link>
  ) : (
    <PrismicNextLink className={className} key={label} field={url}>
      <Button size={size}>
        {label}
        {with_arrow ? <ArrowRightIcon className="ml-2 size-4" /> : null}
      </Button>
    </PrismicNextLink>
  );
};

export default SliceCta;
