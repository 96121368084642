import { JSXMapSerializer } from '@prismicio/react';

import slugify from 'slugify';

import { cn } from '@/lib/utils';

interface HeadingProps {
  textAlign?: 'left' | 'center' | 'right';
  theme?: 'primary' | 'white';
  fontWeight?: 'normal' | 'bold';
  className?: string;
}

const mapTitleTextAlignToTailwindClassName = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
};

const mapTextAlignToTailwindClassName = {
  left: 'text-left sm:text-justify',
  center: 'text-center',
  right: 'text-right',
};

const mapThemeToTailwindClassName = {
  primary: '',
  white: 'text-white',
};

const mapFontWeightToTailwindClassName = {
  normal: '',
  bold: 'font-bold',
};

const heading = (
  {
    textAlign = 'center',
    theme = 'primary',
    fontWeight = 'normal',
    className,
  }: HeadingProps = {
    textAlign: 'center',
    theme: 'primary',
    fontWeight: 'normal',
  },
): JSXMapSerializer => {
  const additionnalClassnames = [mapThemeToTailwindClassName[theme], className];

  return {
    heading1: ({ children }) => (
      <h1
        className={cn(
          'font-heading text-3xl font-semibold leading-normal md:text-5xl xl:max-w-3/4',
          mapTitleTextAlignToTailwindClassName[textAlign],
          ...additionnalClassnames,
        )}
      >
        {children}
      </h1>
    ),
    heading2: ({ children, node }) => (
      <h2
        id={slugify(node.text, {
          lower: true,
          strict: true,
        })}
        className={cn(
          'scroll-m-32 font-heading text-2xl font-bold md:text-3xl',
          mapTitleTextAlignToTailwindClassName[textAlign],
          ...additionnalClassnames,
        )}
      >
        {children}
      </h2>
    ),
    heading3: ({ children }) => (
      <h3
        className={cn(
          'font-heading text-xl font-bold md:text-2xl',
          mapTitleTextAlignToTailwindClassName[textAlign],
          ...additionnalClassnames,
        )}
      >
        {children}
      </h3>
    ),
    heading4: ({ children }) => (
      <h4
        className={cn(
          'font-heading text-lg font-bold md:text-xl',
          mapTitleTextAlignToTailwindClassName[textAlign],
          ...additionnalClassnames,
        )}
      >
        {children}
      </h4>
    ),
    // @adr /prismic/ACTIVE-20240213-format-richtext.md
    strong: ({ children }) => <span className="text-primary">{children}</span>,
    paragraph: ({ children }) => {
      const isEmpty = (() => {
        try {
          return (
            children.length === 1 &&
            // @ts-expect-error: safe since within a try/catch
            children[0].props.children[0].props.children === ''
          );
        } catch {
          return false;
        }
      })();

      // We check if the children is an only child with content "" to replace it with a <br />
      // Allowing content writer to skip lines in a semantic way
      return isEmpty ? (
        <br />
      ) : (
        <p
          className={cn(
            'w-full leading-7',
            ...additionnalClassnames,
            mapFontWeightToTailwindClassName[fontWeight],
            mapTextAlignToTailwindClassName[textAlign],
          )}
        >
          {children}
        </p>
      );
    },
    hyperlink: ({ children, node }) => (
      <a
        className="text-primary underline"
        href={node.data.url}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    ),
    list: ({ children }) => (
      <ul className="ml-4 list-outside list-disc">{children}</ul>
    ),
    oList: ({ children }) => (
      <ol className="ml-4 list-outside list-decimal">{children}</ol>
    ),
  };
};

export default heading;
