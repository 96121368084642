'use client';

import { Content } from '@prismicio/client';

import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';

import AutoHeight from 'embla-carousel-auto-height';
import Autoplay, { AutoplayType } from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import { useCallback } from 'react';

import { Button } from '@/app/_components/ui/button';
import { Icons } from '@/app/_components/ui/icons';

import SliceCta from '@/slices/_shared/cta';
import NewsTag from '@/slices/NewsCarousel/_components/news-tag';

interface MobileNewsCarouselProps {
  speed: number;
  items: Content.NewsCarouselSliceDefaultItem[];
  components: JSXMapSerializer;
}

const MobileNewsCarousel = ({
  speed,
  items,
  components,
}: MobileNewsCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ axis: 'x', loop: true }, [
    AutoHeight(),
    Autoplay({
      delay: speed,
      stopOnInteraction: false,
    }),
  ]);

  const onPrevButtonClick = useCallback(() => {
    const autoplay: AutoplayType | undefined = emblaApi?.plugins()?.autoplay;
    if (!emblaApi || !autoplay) {
      return;
    }

    emblaApi.scrollPrev();
    autoplay.reset();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    const autoplay: AutoplayType | undefined = emblaApi?.plugins()?.autoplay;
    if (!emblaApi || !autoplay) {
      return;
    }

    emblaApi.scrollNext();
    autoplay.reset();
  }, [emblaApi]);

  return (
    <div className="flex w-full flex-col overflow-hidden rounded-b-xl rounded-t-md shadow-lg md:hidden">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="-ml-4 flex touch-pan-y backface-hidden">
          {items.map(
            (
              {
                title,
                summary,
                label_name,
                label_color,
                illustration,
                mobile_title,
                mobile_summary,
                button,
              },
              index,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="relative h-fit min-w-0 flex-100 pl-4" key={index}>
                <div className="flex flex-col">
                  <Image
                    src={illustration.url ?? ''}
                    role="presentation"
                    objectFit="cover"
                    height={208}
                    width={1000}
                    sizes="100%"
                    className="h-52 rounded-t-md object-cover"
                    alt=""
                    // If the image from "images.prismic" domain, it went through imgix and can be optimized.
                    unoptimized={!illustration.url?.includes('images.prismic')}
                  />

                  <SliceCta
                    size="sm"
                    field={button}
                    className="absolute right-2 top-[calc(11rem-8px)]"
                  />

                  <div className="flex h-48 flex-col gap-y-2 p-4">
                    <NewsTag color={label_color} label={label_name} />

                    <PrismicRichText
                      components={components}
                      field={mobile_title.length > 0 ? mobile_title : title}
                    />

                    <PrismicRichText
                      components={components}
                      field={
                        mobile_summary.length > 0 ? mobile_summary : summary
                      }
                    />
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>

      <div className="flex flex-col items-center gap-x-4 p-4">
        <div className="flex flex-row gap-x-2">
          <Button
            className="size-12 p-0"
            cornerStyle="pillLeft"
            onClick={onPrevButtonClick}
          >
            <Icons.StraightLeftArrow className="size-6" />
          </Button>

          <Button
            className="size-12 p-0"
            cornerStyle="pillRight"
            onClick={onNextButtonClick}
          >
            <Icons.RightArrow className="size-6" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileNewsCarousel;
