'use client';

import { Content } from '@prismicio/client';

import { JSXMapSerializer, SliceComponentProps } from '@prismicio/react';

import { useBreakpoint } from '@/lib/style/hooks';

import { cn } from '@/lib/utils';
import heading from '@/slices/_shared/heading';

import DesktopNewsCarousel from './_components/desktop-carousel';
import MobileNewsCarousel from './_components/mobile-carousel';

const components: JSXMapSerializer = {
  ...heading({ textAlign: 'left' }),
  paragraph: ({ children }) => <p className="text-sm">{children}</p>,
};

/**
 * Props for `NewsCarousel`.
 */
export type NewsCarouselProps = SliceComponentProps<Content.NewsCarouselSlice>;

/**
 * Component for "NewsCarousel" Slices.
 */
const NewsCarousel = ({ slice }: NewsCarouselProps) => {
  const isDesktop = useBreakpoint('md');

  // The amount sent by prismic is in seconds, we need milliseconds
  // By default we set it to 5 seconds
  const speed = slice.primary.carousel_speed
    ? slice.primary.carousel_speed * 1000
    : 5000;

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={cn(
        'flex justify-center px-6 pt-10 md:px-20 xl:px-32',
        slice.primary.remove_top_spacing ? 'pt-0' : 'pt-16',
        slice.primary.remove_bottom_spacing ? 'pb-0' : 'pb-24',
      )}
    >
      {isDesktop ? (
        <DesktopNewsCarousel
          speed={speed}
          items={slice.items}
          components={components}
          backgroundColor={slice.primary.background_color}
        />
      ) : (
        <MobileNewsCarousel
          speed={speed}
          items={slice.items}
          components={components}
        />
      )}
    </section>
  );
};

export default NewsCarousel;
